<script setup>
import { inject, ref } from 'vue'
import FromUntilDateTime from '@/components/utils/FromUntilDateTime.vue'

defineProps({
  maintenance: {
    type: Object
  }
})

const locale = inject('locale')
const open = ref(false)
</script>

<template>
  <div
    class="border border-gray-300 dark:border-white/75 rounded py-1 px-2 cursor-pointer"
    @click="open = !open"
  >
    <div class="flex items-start justify-between gap-3">
      <div>
        <p class="font-semibold">{{ maintenance.name }}</p>
        <p class="italic text-gray-500 dark:text-white/75 capitalize">{{ maintenance.status }}</p>
      </div>
      <FromUntilDateTime :until="maintenance.scheduled_until" :from="maintenance.scheduled_for" />
    </div>

    <div
      :class="{
        'h-0 overflow-hidden opacity-0': !open,
        'border-t border-gray-300 dark:border-white/75 border-dashed mt-1 pt-1': open
      }"
    >
      <div
        v-for="update in maintenance.incident_updates"
        :key="update.id"
        class="flex items-start justify-between text-sm"
      >
        <div>
          <p class="capitalize font-semibold">{{ update.status }}</p>
          <p class="italic">{{ update.body }}</p>
          <ul class="list-disc ml-4 text-xs mt-1">
            <li v-for="component in update.affected_components" :key="component.code">
              {{ component.name }}
            </li>
          </ul>
        </div>
        <p>
          {{
            new Date(update.display_at).toLocaleDateString(locale.value, {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            })
          }}
        </p>
      </div>
    </div>
  </div>
</template>
