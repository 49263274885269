<script setup>
import { SquareActivity, Loader2, Ban, ExternalLink } from 'lucide-vue-next'
import AppTooltip from '@/components/utils/AppTooltip.vue'
import { onMounted, ref } from 'vue'
import AppModal from '@/components/utils/AppModal.vue'
import ServiceStatusIncidents from '@/components/ServiceStatus/ServiceStatusIncidents.vue'
import ServiceStatusMaintenances from '@/components/ServiceStatus/ServiceStatusMaintenances.vue'
import ServiceStatusComponents from '@/components/ServiceStatus/ServiceStatusComponents.vue'

const fetching = ref(false)
const fetchError = ref(false)
const data = ref(null)
const open = ref(false)

onMounted(() => {
  fetchServiceStatusSummary()
})

async function fetchServiceStatusSummary() {
  fetching.value = true
  fetchError.value = false

  try {
    const response = await fetch('https://s310k6tb544z.statuspage.io/api/v2/summary.json')

    if (!response.ok) {
      fetchError.value = true
    }

    data.value = await response.json()

    fetching.value = false
  } catch (e) {
    console.log(e)
    fetchError.value = true
    fetching.value = false
  }
}
</script>

<template>
  <div v-bind="$attrs">
    <Loader2 v-if="fetching" :size="20" class="animate-spin" />
    <template v-else>
      <AppTooltip
        v-if="fetchError"
        text="Error Fetching System Status"
        position="left"
        :small="true"
      >
        <Ban :size="20" class="text-rose-500" />
      </AppTooltip>
      <AppTooltip v-else-if="data" :text="data.status.description" position="left" :small="true">
        <button
          type="button"
          class="relative p-0.5 opacity-75 hover:opacity-100"
          @click="open = true"
        >
          <SquareActivity :size="20" />
          <div
            v-if="data.status.indicator === 'minor'"
            class="absolute top-0 right-0 rounded-full size-2 bg-yellow-500"
          ></div>
          <div
            v-else-if="data.status.indicator === 'major'"
            class="absolute top-0 right-0 rounded-full size-2 bg-orange-500"
          ></div>
          <div
            v-else-if="data.status.indicator === 'critical'"
            class="absolute top-0 right-0 rounded-full size-2 bg-rose-500"
          ></div>
          <div
            v-else-if="data.scheduled_maintenances && data.scheduled_maintenances.length > 0"
            class="absolute top-0 right-0 rounded-full size-2 bg-sky-500"
          ></div>
        </button>
      </AppTooltip>
    </template>
  </div>

  <AppModal v-if="open" @close="() => (open = false)">
    <template #title>{{ $t('systemStatus') }}</template>
    <template #default>
      <div
        class="rounded p-2 font-semibold text-white flex items-center justify-between mb-3 min-w-96"
        :class="{
          'bg-rose-500': data.status.indicator === 'critical',
          'bg-orange-500': data.status.indicator === 'major',
          'bg-yellow-500': data.status.indicator === 'minor',
          'bg-emerald-500': data.status.indicator === 'none'
        }"
      >
        <p>{{ data.status.description }}</p>
        <a :href="data.page.url" target="_blank">
          <ExternalLink :size="18" />
        </a>
      </div>
      <ServiceStatusComponents :components="data.components" class="mb-12" />
      <ServiceStatusIncidents :incidents="data.incidents" class="mb-12" />
      <ServiceStatusMaintenances :maintenances="data.scheduled_maintenances" />
    </template>
  </AppModal>
</template>
