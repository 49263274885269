<script setup>
import ComponentItem from '@/components/ServiceStatus/ComponentItem.vue'
import { ref } from 'vue'
import { ChevronRight } from 'lucide-vue-next'

defineProps({
  component: {
    type: Object
  },
  childComponents: {
    type: Array,
    default: () => []
  }
})

const open = ref(false)
</script>

<template>
  <div
    class="border border-gray-300 dark:border-brand-extra-light dark:bg-brand rounded py-1 px-2"
    :class="{ 'cursor-pointer': childComponents.length > 0 }"
    @click="open = !open"
  >
    <div class="flex items-center justify-between">
      <ComponentItem :component="component" />
      <ChevronRight
        v-if="childComponents.length > 0"
        :size="24"
        :stroke-width="1"
        class="transition-all"
        :class="{ 'rotate-90': open }"
      />
    </div>
    <div v-if="open">
      <div v-for="child in childComponents" :key="child.id" class="flex items-center gap-2 group">
        <div class="grid grid-cols-1 grid-rows-2 ml-[3px]">
          <div class="border-gray-300 dark:border-white/75 border-l-2 border-b-2 size-3"></div>
          <div
            class="border-gray-300 dark:border-white/75 border-l-2 group-last:border-l-transparent"
          ></div>
        </div>
        <ComponentItem :component="child" />
      </div>
    </div>
  </div>
</template>
