<script setup>
import { inject, ref } from 'vue'
import { RouterLink } from 'vue-router'
import { vOnClickOutside } from '@vueuse/components'

const open = ref(false)
const oidc = inject('oidc')
const user = inject('user')

function close() {
  open.value = false
}
</script>

<template>
  <div class="relative z-40" v-on-click-outside="close">
    <div
      class="flex items-center gap-3 border border-transparent hover:border-brand-extra-light hover:bg-brand-light dark:hover:bg-brand cursor-pointer p-1 rounded"
      :class="{
        'bg-brand-light dark:bg-brand border-t-brand-extra-light border-l-brand-extra-light border-r-brand-extra-light rounded-b-0':
          open
      }"
      @click="open = !open"
    >
      <div
        v-if="user !== undefined"
        class="w-8 h-8 rounded-full overflow-hidden bg-brand-light border border-brand-extra-light flex justify-center items-center font-bold"
      >
        <p>{{ user.given_name.charAt(0) }}</p>
      </div>
      <div v-if="user !== undefined" class="text-xs text-white">
        <p>{{ user.given_name }}</p>
        <p>{{ user.family_name }}</p>
      </div>
    </div>
    <div
      v-if="open"
      class="absolute top-10 w-full bg-brand dark:bg-brand-dark border border-brand-extra-light rounded-b shadow shadow-brand-dark"
    >
      <ul>
        <li>
          <RouterLink
            :to="{ name: 'account' }"
            class="block text-center text-sm hover:bg-brand-light dark:hover:bg-brand py-2 px-4 rounded-b"
            @click="close"
            >{{ $t('account') }}</RouterLink
          >
        </li>
        <li>
          <button
            type="button"
            class="w-full block text-center text-sm hover:bg-brand-light dark:hover:bg-brand py-2 px-4 rounded-b"
            @click="oidc.signoutRedirect()"
          >
            {{ $t('logout') }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
