<script setup>
import { inject, ref } from 'vue'
import AppTooltip from '@/components/utils/AppTooltip.vue'
import { ChevronRight } from 'lucide-vue-next'

defineProps({
  incident: {
    type: Object
  }
})

const locale = inject('locale')
const open = ref(false)
</script>

<template>
  <div
    class="border border-gray-300 dark:border-white/75 border-l-4 rounded-r py-1 px-2 cursor-pointer"
    :class="{
      'border-l-rose-500': incident.impact === 'critical',
      'border-l-orange-500': incident.impact === 'major',
      'border-l-yellow-500': incident.impact === 'minor',
      'border-l-gray-500': incident.impact === 'none'
    }"
    @click="open = !open"
  >
    <div class="flex items-start justify-between gap-3">
      <div>
        <p class="font-semibold">{{ incident.name }}</p>
        <p class="italic text-gray-500 dark:text-white/75 capitalize">{{ incident.status }}</p>
      </div>
      <div class="space-y-1">
        <div
          v-for="component in incident.components"
          :key="component.id"
          class="flex items-center gap-1.5"
        >
          <AppTooltip :text="component.status" :small="true">
            <div
              class="size-2 rounded-full"
              :class="{
                'bg-rose-500': component.status === 'major_outage',
                'bg-orange-500': component.status === 'partial_outage',
                'bg-yellow-500': component.status === 'degraded_performance',
                'bg-emerald-500': component.status === 'operational'
              }"
            ></div>
          </AppTooltip>
          <p class="text-sm font-semibold text-gray-500 dark:text-white/75">{{ component.name }}</p>
        </div>
      </div>
      <div class="flex flex-col items-end justify-between gap-1">
        <p class="text-sm">
          {{
            new Date(incident.started_at).toLocaleDateString(locale.value, {
              weekday: 'long',
              day: '2-digit',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit'
            })
          }}
        </p>
        <ChevronRight
          :size="24"
          :stroke-width="1"
          class="transition-all"
          :class="{ 'rotate-90': open }"
        />
      </div>
    </div>

    <div
      :class="{
        'h-0 overflow-hidden opacity-0': !open,
        'border-t border-gray-300 dark:border-white/75 border-dashed mt-1 pt-1': open
      }"
    >
      <div
        v-for="update in incident.incident_updates"
        :key="update.id"
        class="flex items-start justify-between text-sm"
      >
        <div>
          <p class="capitalize font-semibold">{{ update.status }}</p>
          <p class="italic">{{ update.body }}</p>
        </div>
        <p>
          {{
            new Date(update.display_at).toLocaleDateString(locale.value, {
              day: '2-digit',
              month: '2-digit',
              year: '2-digit',
              hour: '2-digit',
              minute: '2-digit'
            })
          }}
        </p>
      </div>
    </div>
  </div>
</template>
