<script setup>
import { computed } from 'vue'
import ComponentSection from '@/components/ServiceStatus/ComponentSection.vue'

const props = defineProps({
  components: {
    type: Array,
    default: () => []
  }
})

const parentComponents = computed(() => {
  return props.components.filter((c) => c.group_id === null)
})

function childComponents(component) {
  return props.components.filter((c) => {
    if (!Array.isArray(component.components)) {
      return false
    }

    return component.components.includes(c.id)
  })
}
</script>

<template>
  <div class="space-y-1">
    <ComponentSection
      v-for="component in parentComponents"
      :key="component.id"
      :component="component"
      :child-components="childComponents(component)"
    />
  </div>
</template>
