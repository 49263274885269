<script setup>
import { onMounted, ref } from 'vue'
import { useMouseInElement, useMutationObserver } from '@vueuse/core'

const props = defineProps({
  text: {
    type: [String, null],
    required: true
  },
  small: {
    type: Boolean,
    default: false
  },
  position: {
    type: String,
    default: 'right',
    validator(value) {
      return ['right', 'left', 'top'].includes(value)
    }
  }
})

const slotWrapper = ref(null)
const tooltip = ref(null)
const style = ref({})

const slotWidth = ref(null)
const slotHeight = ref(null)
const tooltipWidth = ref(null)
const tooltipHeight = ref(null)

const { isOutside } = useMouseInElement(slotWrapper)
useMutationObserver(
  slotWrapper,
  () => {
    calcStyle()
  },
  { childList: true }
)

onMounted(() => {
  calcStyle()
})

function calcStyle() {
  if (tooltip.value === null) {
    return
  }

  slotWidth.value = slotWrapper.value.clientWidth
  slotHeight.value = slotWrapper.value.clientHeight
  tooltipWidth.value = tooltip.value.clientWidth
  tooltipHeight.value = tooltip.value.clientHeight

  style.value = {
    left: slotWidth.value - (props.small ? 3 : 5) + 'px',
    top: (slotHeight.value - tooltipHeight.value) / 2 + 'px'
  }

  if (props.position === 'left') {
    style.value = {
      right: slotWidth.value - (props.small ? 3 : 5) + 'px',
      top: (slotHeight.value - tooltipHeight.value) / 2 + 'px'
    }
  }

  if (props.position === 'top') {
    style.value = {
      left: slotWidth.value / 2 - tooltipWidth.value / 2 + 'px',
      top: '-' + (slotHeight.value + (props.small ? 2 : 7)) + 'px'
    }
  }
}
</script>

<template>
  <div class="relative w-fit">
    <div ref="slotWrapper" class="w-fit">
      <slot></slot>
    </div>
    <div
      v-if="text && text.length > 0"
      ref="tooltip"
      class="opacity-0 absolute z-20 transition-opacity duration-200 cursor-default pointer-events-none"
      :class="{ 'opacity-100': !isOutside }"
      :style="style"
    >
      <div class="flex items-center" :class="{ 'flex-col': position === 'top' }">
        <div
          v-if="position === 'right'"
          class="h-0 w-0 border-transparent border-r-black/70 dark:border-r-white/80"
          :class="{ 'border-[6px]': !small, 'border-[4px]': small }"
        ></div>
        <div
          class="rounded bg-black/70 dark:bg-white/80 text-white dark:text-black"
          :class="{ 'py-1 px-2 text-sm': !small, 'py-0.5 px-1.5 text-xs': small }"
        >
          <p class="whitespace-nowrap">{{ text }}</p>
        </div>
        <div
          v-if="position === 'left'"
          class="h-0 w-0 border-transparent border-l-black/70 dark:border-l-white/80"
          :class="{ 'border-[6px]': !small, 'border-[4px]': small }"
        ></div>
        <div
          v-if="position === 'top'"
          class="h-0 w-0 border-transparent border-t-black/70 dark:border-t-white/80"
          :class="{ 'border-[6px]': !small, 'border-[4px]': small }"
        ></div>
      </div>
    </div>
  </div>
</template>
