import { createRouter, createWebHistory } from 'vue-router'
import getEnv from '@/utils/env.js'

const router = createRouter({
  history: createWebHistory(getEnv('BASE_URL')),
  routes: [
    {
      path: '/',
      name: 'dashboard',
      component: () => import('@/views/DashboardView.vue')
    },
    {
      path: '/result',
      name: 'searchResult',
      component: () => import('@/views/SearchResultView.vue')
    },
    {
      path: '/customers',
      name: 'customers',
      component: () => import('@/views/CustomersView.vue'),
      meta: {
        pageTitle: 'customers'
      }
    },
    {
      path: '/customers/:id',
      name: 'customerDetails',
      component: () => import('@/views/CustomerDetailView.vue')
    },
    {
      path: '/ubo/:id',
      name: 'uboDetails',
      component: () => import('@/views/UboDetail.vue')
    },
    {
      path: '/tasks',
      name: 'tasks',
      component: () => import('@/views/TasksView.vue'),
      meta: {
        pageTitle: 'tasks'
      }
    },
    {
      path: '/tasks/:id',
      name: 'taskDetails',
      component: () => import('@/views/TaskDetailView.vue')
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/SettingsView.vue'),
      meta: {
        pageTitle: 'settings'
      }
    },
    {
      path: '/settings/workflow/:id',
      name: 'settingsWorkflow',
      component: () => import('@/views/WorkflowDetail.vue'),
      meta: {
        pageTitle: 'settings'
      }
    },
    {
      path: '/settings/decision/:id',
      name: 'settingsDecision',
      component: () => import('@/views/DecisionDetail.vue'),
      meta: {
        pageTitle: 'settings'
      }
    },
    {
      path: '/settings/form/:id',
      name: 'settingsForm',
      component: () => import('@/views/FormDetail.vue'),
      meta: {
        pageTitle: 'settings'
      }
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('@/views/AccountView.vue'),
      meta: {
        pageTitle: 'account'
      }
    },
    {
      path: '/:catchAll(.*)*',
      component: () => import('@/views/NotFoundView.vue')
    }
  ]
})

export default router
