<script setup>
import { Search, X, History } from 'lucide-vue-next'
import { ref, watch } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { vOnClickOutside } from '@vueuse/components'
import { useRouter } from 'vue-router'

const focus = ref(false)
const open = ref(false)
const term = ref('')
const inputRef = ref(null)
const searchHistory = useLocalStorage('search-history', [])
const router = useRouter()

watch(focus, (newValue, oldValue) => {
  if (oldValue === false && newValue === true) {
    open.value = true
  }
})

function onSubmit() {
  if (term.value.length === 0) {
    return
  }

  updateHistory(term.value)

  router.push({ name: 'searchResult', query: { search_query: term.value } })

  term.value = ''
  open.value = false
  inputRef.value.blur()
}

function selectAnSubmit(historyTerm) {
  inputRef.value.focus()
  term.value = historyTerm

  onSubmit()
}

function removeHistoryItem(historyItemIndex) {
  searchHistory.value.splice(historyItemIndex, 1)
}

function updateHistory(searchTerm) {
  if (!searchHistory.value.includes(searchTerm)) {
    searchHistory.value.push(searchTerm)
  }

  if (searchHistory.value.length > 5) {
    searchHistory.value.shift()
  }
}
</script>

<template>
  <div class="relative" v-on-click-outside="() => (open = false)">
    <form
      @submit.prevent="onSubmit"
      class="flex items-center border border-brand-extra-light rounded-full focus-within:border-sky-600 focus-within:ring-1 ring-sky-600 focus-within:bg-white dark:focus-within:bg-brand dark:bg-brand"
    >
      <input
        ref="inputRef"
        type="text"
        class="bg-transparent border-none focus:ring-0 ml-2 focus:text-black"
        @focus="focus = true"
        @blur="focus = false"
        v-model.trim="term"
      />
      <button
        type="reset"
        class="px-1 self-stretch"
        :class="{
          visible: term.length > 0,
          invisible: term.length === 0,
          'text-white/75 hover:text-white': !focus,
          'text-black/50 hover:text-black': focus
        }"
        @click="term = ''"
      >
        <X :size="18" />
      </button>
      <button
        type="submit"
        class="bg-brand-light self-stretch rounded-r-full flex items-center justify-center px-3"
      >
        <Search :size="16" />
      </button>
    </form>

    <div
      v-if="open"
      class="absolute bg-white dark:bg-brand text-black rounded top-10 shadow-lg min-w-full z-40 flex flex-col-reverse"
      :key="searchHistory.length"
    >
      <div
        v-for="(item, index) in searchHistory"
        :key="index"
        class="py-1 px-2 hover:bg-sky-600/20 first:rounded-b last:rounded-t flex items-center justify-between gap-3"
      >
        <div
          class="flex items-center gap-3 cursor-pointer grow dark:text-white"
          @click="selectAnSubmit(item)"
        >
          <History :size="20" />
          <span>{{ item }}</span>
        </div>
        <button type="button" class="text-xs text-sky-600" @click="removeHistoryItem(index)">
          {{ $t('deleteLabel') }}
        </button>
      </div>
    </div>
  </div>
</template>
