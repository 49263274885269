<script setup>
import { useToastStore } from '@/stores/toast.js'
import { AlertCircle, CheckCircle2, X } from 'lucide-vue-next'

const toastStore = useToastStore()
</script>

<template>
  <teleport to="body">
    <div class="fixed z-40 top-0 right-0 p-1 md:p-3 overflow-hidden print:hidden">
      <transition-group name="toast">
        <template v-for="toast in toastStore.toasts" :key="toast.id">
          <div
            v-if="toast.type === 'success'"
            class="border border-green-700 dark:border-green-900 bg-green-50 dark:bg-green-500/90 rounded shadow-lg mb-3 p-1 md:p-3 text-sm flex items-start gap-1 md:gap-3"
          >
            <CheckCircle2
              :size="24"
              :stroke-width="1"
              class="text-green-700 dark:text-green-900 hidden md:block"
            />
            <div>
              <p class="font-semibold text-green-700 dark:text-green-900">{{ toast.title }}</p>
              <p class="dark:text-gray-100">{{ toast.details }}</p>
            </div>
          </div>
          <div
            v-if="toast.type === 'error'"
            class="border border-red-700 dark:border-red-900 bg-red-50 dark:bg-red-400/90 rounded shadow-lg mb-3 p-1 md:p-3 text-sm flex items-start gap-1 md:gap-3 relative"
          >
            <AlertCircle
              :size="24"
              :stroke-width="1"
              class="text-red-700 dark:text-red-900 hidden md:block"
            />
            <div>
              <p class="font-semibold text-red-700 dark:text-red-900">{{ toast.title }}</p>
              <p class="dark:text-gray-100">{{ toast.details }}</p>
            </div>
            <button
              type="button"
              class="absolute top-1 right-1 p-1 rounded text-gray-500 dark:text-gray-300 hover:bg-red-300 dark:hover:bg-red-700 dark:hover:text-red-200 hover:text-red-700"
              @click="toastStore.removeToast(toast.id)"
            >
              <X :size="14" />
            </button>
          </div>
        </template>
      </transition-group>
    </div>
  </teleport>
</template>

<style scoped>
.toast-enter-active,
.toast-leave-active {
  transition: all 0.5s ease;
}
.toast-enter-from {
  opacity: 0;
  transform: translateY(-3rem);
}
.toast-leave-to {
  opacity: 0;
  transform: translateX(3rem);
}
</style>
