import { useToastStore } from '@/stores/toast.js'

export function useIntercept() {
  const toastStore = useToastStore()

  window.fetch = new Proxy(window.fetch, {
    apply(actualFetch, that, args) {
      const result = Reflect.apply(actualFetch, that, args)

      result.then((response) => {
        const title = typeof args[0] === 'string' ? args[0] : args[0].href
        const details = response.status + ' ' + response.statusText
        const type = [200, 201, 204].includes(response.status) ? 'success' : 'error'

        if (type === 'error') {
          toastStore.addToast(title, details, type)
        }
      })

      return result
    }
  })
}
