<script setup>
import { computed, inject } from 'vue'
import dayjs from 'dayjs'

const props = defineProps({
  from: {
    type: String,
    required: true
  },
  until: {
    type: String,
    required: true
  }
})

const locale = inject('locale')
const isSameDay = computed(() => {
  return dayjs(props.from).isSame(props.until, 'day')
})
</script>

<template>
  <div v-if="isSameDay" class="text-sm text-center">
    <p>
      {{
        new Date(from).toLocaleDateString(locale.value, {
          weekday: 'long',
          day: '2-digit',
          month: 'long',
          year: 'numeric'
        })
      }}
    </p>
    <p>
      {{
        new Date(from).toLocaleTimeString(locale.value, {
          hour: '2-digit',
          minute: '2-digit'
        })
      }}
      {{ $t('until') }}
      {{
        new Date(until).toLocaleTimeString(locale.value, {
          hour: '2-digit',
          minute: '2-digit'
        })
      }}
    </p>
  </div>
  <div v-else class="text-sm text-center">
    <p>
      {{
        new Date(from).toLocaleDateString(locale.value, {
          weekday: 'long',
          day: '2-digit',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      }}
    </p>
    <p>{{ $t('until') }}</p>
    <p>
      {{
        new Date(until).toLocaleDateString(locale.value, {
          weekday: 'long',
          day: '2-digit',
          month: 'long',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      }}
    </p>
  </div>
</template>
