import { oidcUser } from '@/utils/oidc.js'
import { usePreferredLanguages } from '@vueuse/core'

export function useUserLocale() {
  let userLocale = oidcUser.profile.locale ? oidcUser.profile.locale.split('-')[0] : undefined

  if (!userLocale) {
    let locales = usePreferredLanguages()
    let unifiedLocales = []
    let availableLanguages = ['de', 'en']

    locales.value.forEach((locale) => {
      unifiedLocales.push(locale.split('-')[0])
    })

    unifiedLocales = [...new Set(unifiedLocales)]

    unifiedLocales.forEach((preferredLocale) => {
      if (availableLanguages.includes(preferredLocale) && !userLocale) {
        userLocale = preferredLocale
      }
    })
  }

  if (userLocale === undefined) {
    userLocale = 'en'
  }

  return userLocale
}
