import { Log, UserManager } from 'oidc-client-ts'
import getEnv from '@/utils/env.js'
import { jwtDecode } from 'jwt-decode'

export const oidc = new UserManager({
  authority: getEnv('VITE_KEYCLOAK_REALM'),
  client_id: getEnv('VITE_KEYCLOAK_CLIENT_ID'),
  redirect_uri: window.location.origin + window.location.pathname,
  post_logout_redirect_uri: window.location.origin,
  loadUserInfo: true,
  scope: 'openid offline_access'
})

let signRedirectArgs = {}

oidc.events.addSilentRenewError(async (e) => {
  if (e.message === 'Token is not active' || e.message === 'Session not active') {
    console.log('User Session expired')
    await oidc.signinRedirect(signRedirectArgs)
  }
})

if (getEnv('DEV')) {
  Log.setLogger(console)
}

const searchParams = new URLSearchParams(window.location.search)

// Handle SignIn Callback
if (searchParams.has('state')) {
  try {
    await oidc.signinCallback()
  } catch (e) {
    console.log('SignIn Callback', e)
  }

  window.location.replace(window.location.origin + window.location.pathname)
}

export const oidcUser = await oidc.getUser()

if (!oidcUser) {
  console.log('No User Found')
  await oidc.signinRedirect(signRedirectArgs)
}

const currentTime = new Date().getTime() / 1000
const { exp: accessTokenExp } = jwtDecode(oidcUser.access_token)
const { exp: refreshTokenExp } = jwtDecode(oidcUser.refresh_token)

if (currentTime > refreshTokenExp) {
  // Refresh Token expired
  console.log('Refresh Token expired')
  await oidc.revokeTokens(['refresh_token'])
  await oidc.signinRedirect(signRedirectArgs)
}

if (currentTime > accessTokenExp) {
  // Access Token expired
  console.log('Access Token expired')
  try {
    await oidc.signinSilent()
  } catch (e) {
    console.log(e)
    if (e.message === 'auth_time in id_token does not match original auth_time') {
      await oidc.signinRedirect(signRedirectArgs)
    }
  }
}
