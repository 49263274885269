import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useToastStore = defineStore('toast', () => {
  const toasts = ref([])

  function addToast(title, details, type) {
    const randomId = (Math.random() + 1).toString(36).substring(2)
    toasts.value.push({ title: title, details: details, type: type, id: randomId })

    if (type === 'success') {
      setTimeout(() => {
        removeToast(randomId)
      }, 3000)
    }
  }

  function removeToast(id) {
    toasts.value.forEach((toast, index) => {
      if (toast['id'] === id) {
        toasts.value.splice(index, 1)
      }
    })
  }

  return { toasts, addToast, removeToast }
})
