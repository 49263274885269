<script setup>
import { Globe } from 'lucide-vue-next'
import { ref } from 'vue'
import { useOrganizationStore } from '@/stores/organization.js'
import { vOnClickOutside } from '@vueuse/components'
import { useAPIFetch } from '@/composables/apiFetch.js'

defineProps({
  asSelect: {
    Type: Boolean,
    default: false
  }
})

const open = ref(false)
const organizationStore = useOrganizationStore()
const { data } = useAPIFetch('organization', { immediate: true })
const { data: repositories, execute: loadRepositories } = useAPIFetch('organization/:id/repository')

async function selectOrganization(organization) {
  if (organization === undefined) {
    organizationStore.repositories = []
  } else {
    await loadRepositories({ urlParams: { id: organization.id } })
    organizationStore.repositories = repositories.value.items
  }

  organizationStore.selected = organization

  close()
}

function onChange(e) {
  const value = e.target.value

  if (value === 'all') {
    selectOrganization(undefined)
  }

  data.value.items.forEach((item) => {
    if (item.id === value) {
      selectOrganization(item)
    }
  })
}

function close() {
  open.value = false
}
</script>

<template>
  <template v-if="data && data.total > 1">
    <select v-if="asSelect" v-bind="$attrs" @change="onChange">
      <option value="all">{{ $t('showAll') }}</option>
      <option v-for="organization in data.items" :key="organization.id" :value="organization.id">
        {{ organization.name }}
      </option>
    </select>
    <div v-else class="relative" v-bind="$attrs" v-on-click-outside="close">
      <div
        class="border border-transparent hover:border-brand-extra-light hover:bg-brand-light dark:hover:bg-brand cursor-pointer rounded p-2"
        :class="{
          'border-l-brand-extra-light border-t-brand-extra-light border-r-brand-extra-light bg-brand-light dark:bg-brand rounded-b-none border-b-transparent':
            open
        }"
        @click="open = !open"
      >
        <Globe v-if="organizationStore.selected === undefined" :size="18" />
        <p v-else>{{ organizationStore.selected.name }}</p>
      </div>
      <div
        v-if="open"
        class="absolute top-[2.2rem] bg-brand dark:bg-brand-dark border border-brand-extra-light shadow shadow-brand-dark whitespace-nowrap rounded-b rounded-tr z-20"
      >
        <div
          class="p-3 hover:bg-brand-light dark:hover:bg-brand cursor-pointer rounded-tr"
          @click="selectOrganization(undefined)"
        >
          All
        </div>
        <div
          v-for="organization in data.items"
          :key="organization.id"
          class="p-3 hover:bg-brand-light dark:hover:bg-brand cursor-pointer last:rounded-b"
          @click="selectOrganization(organization)"
        >
          {{ organization.name }}
        </div>
      </div>
    </div>
  </template>
</template>
