import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUsersStore = defineStore('users', () => {
  const users = ref([])

  function setUsers(rawUsers) {
    rawUsers.forEach((user) => {
      user.name = user.first_name + ' ' + user.last_name
      users.value.push(user)
    })
  }

  function getUserById(id) {
    let result = undefined

    users.value.forEach((user) => {
      if (user.id === id) {
        result = user
      }
    })

    return result
  }

  return { users, setUsers, getUserById }
})
